import styles from "./FAQS.module.scss";
import betman from "src/modules/landing/assets/img/png/betmanProfile.png";
import arrowDiagonal from "src/modules/landing/assets/img/svg/arrowDiagonal.svg";
import arrowDiagonalWhite from "src/modules/landing/assets/img/svg/arrowDiagonalWhite.svg";
import cl from "classnames";
import shadowFAQS from "src/modules/landing/assets/img/png/shadowFAQS.png";
import shadowRed from "src/modules/landing/assets/img/png/shadowRed.png";
import shadowFAQSMobile from "src/modules/landing/assets/img/png/shadowFAQSMobile.png";
import { useWindowSize } from "@uidotdev/usehooks";

export const FAQS = () => {
	const windowSize = useWindowSize();

	const arrow = (
		<svg
			width="44"
			height="44"
			viewBox="0 0 44 44"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M41.4629 20.242V23.7788H39.6945V25.5472H37.9261V27.3156H36.1577V29.0839L34.3893 29.0839V30.8523H32.6209V32.6207H30.8525V34.3891H29.0841L29.0841 36.1575H27.3157V37.9259H25.5473V39.6943H23.779V41.4627H22.0106V39.6943H20.2422V37.9259H18.4738V36.1575H20.2422L20.2422 34.3891H22.0106V32.6207H23.779V30.8523H25.5473V29.0839H27.3157V27.3156H29.0841V25.5472H2.55829L2.55829 18.4736H29.0841V16.7052H27.3157V14.9368H25.5473V13.1684H23.779V11.4001H22.0106V9.63166H20.2422V7.86327H18.4738V6.09488H20.2422V4.32649H22.0106V2.55811L23.779 2.55811V4.32649L25.5473 4.32649V6.09488H27.3157V7.86327H29.0841V9.63166H30.8525V11.4001H32.6209V13.1684H34.3893V14.9368H36.1577V16.7052H37.9261V18.4736H39.6945V20.242H41.4629Z"
				fill="#FF0025"
			/>
		</svg>
	);

	return (
		<div className={styles.content}>
			<img src={shadowRed} alt="" className={styles.shadowRed} />
			<img
				src={Number(windowSize.width) > 500 ? shadowFAQS : shadowFAQSMobile}
				alt=""
				className={styles.shadowFAQS}
			/>
			<div className={styles.title}>FAQS</div>
			<div className={styles.wTabs}>
				<div className={styles.tabs}>
					<div className={styles.tab}>
						<div>What are NFTs?</div>
						<div className={styles.hideText}>
							- NFTs, or Non-Fungible Tokens, are unique digital assets stored
							on a blockchain, representing ownership or proof of authenticity
							of a specific item. Unlike cryptocurrencies like Bitcoin or
							Ethereum, which are fungible (meaning each unit is identical and
							can be exchanged one-to-one), NFTs are non-fungible, meaning each
							token is distinct and not interchangeable.
						</div>
						{arrow}
					</div>
					<div className={styles.tab}>
						<div>
							What's the main benefit of minting and holding a SigmaVerse NFT?
						</div>
						<div className={styles.hideText}>
							- You own a part of our Telegram game - SigmaVerse. You get a % of
							revenue like a shareholder, and become a DAO member. In the
							future, SigmaVerse NFTs are likely to generate more revenue
							monthly than their original floor price.
						</div>
						{arrow}
					</div>
				</div>
				<img src={betman} alt="" className={styles.betman} />
				<div className={cl(styles.tabs, styles.tabsRight)}>
					<div className={styles.tab}>
						<div>What is SigmaScore?</div>
						<div className={styles.hideText}>
							- Each NFT possesses its own unique combination of traits. Each
							trait has its own numeric value. SigmaScore is a total sum of
							traits and their values. During mint, SigmaScore is being
							generated randomly. Higher score is likely to be more valuable in
							the future due to its rarity.
						</div>
						{arrow}
					</div>
					<div className={styles.tab}>
						<div>On which network SigmaVerse collection is going to be?</div>
						<div className={styles.hideText}>
							- It may be on Ethereum, Solana or Tron. Wait till launch date to
							find out!
						</div>
						{arrow}
					</div>
				</div>
			</div>
		</div>
	);
};
