import { IntlProvider, useIntl } from "react-intl";
import locales from "./assets/i18n";
import { MainPage } from "./modules/landing/ui/MainPage/MainPage";
import { HashRouter } from "react-router-dom";
import { useContext } from "react";
import { Context } from "./index";

function App() {
	const intl = useIntl();
	const { storeUser } = useContext(Context);

	// For translations into languages
	const messages = {
		...locales.en,
		...locales[storeUser.language],
	};

	return (
		<HashRouter>
			<IntlProvider locale={storeUser.language} messages={messages}>
				<MainPage />
			</IntlProvider>
		</HashRouter>
	);
}

export default App;
