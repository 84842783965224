import styles from "./CollectionUtility.module.scss";
import arrowRight from "src/modules/landing/assets/img/svg/arrowRight.svg";
import cl from "classnames";
import fightLeft from "src/modules/landing/assets/img/png/fightLeft.png";
import fightRight from "src/modules/landing/assets/img/png/fightRight.png";
import loot from "src/modules/landing/assets/img/png/loot.png";
import perks from "src/modules/landing/assets/img/png/perks.png";
import ScrollAnimation from "react-animate-on-scroll";
import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { useWindowSize } from "@uidotdev/usehooks";
import "swiper/css";
import { useIntl } from "react-intl";

export const CollectionUtility = () => {
	const [swiper, setSwiper] = useState<any>();
	const [realIndex, setRealIndex] = useState<number>(0);
	const windowSize = useWindowSize();
	const intl = useIntl();

	const nextTo = () => {
		console.log("next");
		swiper !== undefined && swiper.slideNext();
	};

	const prevTo = () => {
		console.log("prev");
		swiper !== undefined && swiper.slidePrev();
	};

	return (
		<div className={styles.wContent}>
			<div className={styles.content}>
				<div className={styles.titleBlock}>
					<div className={styles.title}>
						{intl.formatMessage({ id: "collection UTILITY" })}
					</div>
					<div className={styles.titleRight}>
						<div>
							{intl.formatMessage({ id: "WHY YOU" })}{" "}
							<span>{intl.formatMessage({ id: "NEED" })}</span>{" "}
							{intl.formatMessage({ id: "AN NFT?" })}
						</div>
						<div style={{ width: Number(windowSize.width) > 500 ? 337 : 281 }}>
							<div>
								{intl.formatMessage({
									id: "Get acquainted with all the perks. We’ve got a lot to show!",
								})}
							</div>
						</div>
					</div>
				</div>
				<div className={styles.wCards}>
					{" "}
					<div
						className={cl(styles.arrowRight, styles.left)}
						onClick={() => prevTo()}
					>
						<img src={arrowRight} alt="" />
					</div>
					<div
						className={cl(styles.arrowRight, styles.right)}
						onClick={() => nextTo()}
					>
						<img src={arrowRight} alt="" />
					</div>
					<Swiper
						className={styles.cards}
						slidesPerView={
							Number(windowSize.width) > 1416
								? 4
								: Number(windowSize.width) > 1060
								? 3
								: Number(windowSize.width) > 720
								? 2
								: 1
						}
						loop={true}
						onSwiper={(s: any) => {
							setSwiper(s);
						}}
						spaceBetween={28}
					>
						<SwiperSlide>
							<ScrollAnimation
								animateIn={styles.animateCard1}
								initiallyVisible={true}
							>
								<div className={cl(styles.card, styles.card1)}>
									<div className={styles.wCardTop}>
										<div className={styles.cardTop}>
											<div>
												{intl
													.formatMessage({
														id: "25% SigmaVerse Revenue Sharing",
													})
													.replace("PIXN", "SigmaVerse")}
											</div>
											<img
												src={fightLeft}
												alt=""
												className={styles.fightLeft}
											/>
											<img
												src={fightRight}
												alt=""
												className={styles.fightRight}
											/>
										</div>
									</div>
									<div className={styles.border}></div>
									<div className={styles.wList}>
										<ul className={styles.list}>
											<li>
												{intl.formatMessage({
													id: "25% of total game revenue will be distributed among 1000 NFT holders.",
												})}
											</li>
											<li>
												{intl
													.formatMessage({
														id: "For every NFT, get 0.025% of PIXN revenue (250$ for every $1M revenue)",
													})
													.replace("PIXN", "SigmaVerse")}
											</li>
										</ul>
									</div>
								</div>
							</ScrollAnimation>
						</SwiperSlide>
						<SwiperSlide>
							<ScrollAnimation
								animateIn={styles.animateCard2}
								initiallyVisible={true}
							>
								<div className={cl(styles.card, styles.card2)}>
									<div className={styles.wCardTop}>
										<div className={styles.cardTop}>
											<div>
												{intl.formatMessage({
													id: "Exclusive In-game Perks",
												})}
											</div>
											<img src={loot} alt="" className={styles.loot} />
										</div>
									</div>
									<div className={styles.border}></div>
									<div className={styles.wList}>
										<ul className={styles.list}>
											<li>
												{intl.formatMessage({
													id: "Exclusive Weekly Lootbox With $$$ Prizes.",
												})}
											</li>
											<li>
												{intl.formatMessage({
													id: "Auto-tap and more.",
												})}
											</li>
										</ul>
									</div>
								</div>
							</ScrollAnimation>
						</SwiperSlide>
						<SwiperSlide>
							<ScrollAnimation
								animateIn={styles.animateCard3}
								initiallyVisible={true}
							>
								<div className={cl(styles.card, styles.card3)}>
									<div className={styles.wCardTop}>
										<div className={styles.cardTop}>
											<div>
												{intl
													.formatMessage({
														id: "PIXN NFT DAO",
													})
													.replace("PIXN", "SigmaVerse")}
											</div>
											<div className={styles.launch}>
												{intl.formatMessage({
													id: "Launches in Q4, 2024",
												})}
											</div>
										</div>
									</div>
									<div className={styles.border}></div>
									<div className={styles.wList}>
										<ul className={styles.list}>
											<li>
												{intl
													.formatMessage({
														id: "Become a co-owner of PIXN game.",
													})
													.replace("PIXN", "SigmaVerse")}
											</li>
											<li>
												{intl.formatMessage({
													id: "Governance votes.",
												})}
											</li>
										</ul>
									</div>
								</div>
							</ScrollAnimation>
						</SwiperSlide>
						<SwiperSlide>
							<ScrollAnimation
								animateIn={styles.animateCard4}
								initiallyVisible={true}
							>
								<div className={cl(styles.card, styles.card4)}>
									<div className={styles.wCardTop}>
										<div className={styles.cardTop}>
											<div>
												{intl.formatMessage({
													id: "Exclusive Access + OG Perks",
												})}
											</div>
											<img src={perks} alt="" className={styles.perks} />
										</div>
									</div>
									<div className={styles.border}></div>
									<div className={styles.wList}>
										<ul className={styles.list}>
											<li>
												{intl
													.formatMessage({
														id: "Access to a private trading community - PIXN.",
													})
													.replace("PIXN", "#1MCLUB")}
											</li>
											<li>
												{intl.formatMessage({
													id: "Access to future IRL events.",
												})}
											</li>
											<li>
												{intl.formatMessage({
													id: "Whitelist spots for possible future launches / events.",
												})}
											</li>
										</ul>
									</div>
								</div>
							</ScrollAnimation>
						</SwiperSlide>
					</Swiper>
				</div>
				<div className={styles.collect}>
					<div>
						{intl.formatMessage({
							id: "Collect all 10 unique characters and gain access to an exclusive Pump Fund group",
						})}
					</div>
				</div>
				<div className={styles.exclusive}>
					{intl.formatMessage({
						id: "EXCLUSIVE",
					})}
				</div>
			</div>
		</div>
	);
};
