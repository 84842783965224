import styles from "./AboutTheCollection.module.scss";
import backgroundRed from "src/modules/landing/assets/img/svg/redBackground.svg";
import pepe from "src/modules/landing/assets/img/png/character.png";
import ClaimButton from "../ClaimButton/ClaimButton";
import arrowRight from "src/modules/landing/assets/img/svg/arrowRight.svg";
import cl from "classnames";
import ScrollAnimation from "react-animate-on-scroll";
import { useIntl } from "react-intl";

export const AboutTheCollection = () => {
	const intl = useIntl();

	return (
		<div className={styles.content} id={"aboutTheCollection"}>
			<div className={styles.title}>
				<div>{intl.formatMessage({ id: "watch tutorial!" })}</div>
				<div className={styles.comingSoon}>
					{intl.formatMessage({ id: "coming soon" })}
				</div>
			</div>
			<div className={styles.mainContent}>
				<div className={styles.right}></div>
				<div className={styles.left}></div>
				<img src={backgroundRed} alt="" className={styles.backgroundRed} />
				<div className={styles.titleBlock}>
					<div className={styles.about}>
						{intl.formatMessage({ id: "about" })}
					</div>
					<div className={styles.titleRow}>
						<div className={styles.about}>
							{intl.formatMessage({ id: "the" })}
						</div>
					</div>
					<div className={styles.about}>
						{intl.formatMessage({ id: "collection" })}
					</div>
				</div>
				<img src={pepe} alt="" className={styles.pepe} />
				<div className={styles.cards}>
					<div className={cl(styles.card, styles.card1)}>
						<div>1000</div>
						<div>NFTs</div>
					</div>
					<div className={cl(styles.card, styles.card2)}>
						<div>{intl.formatMessage({ id: "exclusive" })}</div>
						<div>
							{intl
								.formatMessage({
									id: "limited PIXN founding collection",
								})
								.replace("PIXN", "SigmaVerse")}
						</div>
					</div>
					<div className={cl(styles.card, styles.card3)}>
						<div>25%</div>
						<div>
							{intl
								.formatMessage({
									id: "PIXN Revenue Distributed",
								})
								.replace("PIXN", "SigmaVerse")}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
