import styles from "./Roadmap.module.scss";
import upload from "src/modules/landing/assets/img/svg/upload.svg";
import pencil from "src/modules/landing/assets/img/svg/pencil.svg";
import earth from "src/modules/landing/assets/img/svg/earth.svg";
import bolt from "src/modules/landing/assets/img/svg/bolt.svg";
import lightnings from "src/modules/landing/assets/img/svg/lightnings.svg";
import shadowRoadmap from "src/modules/landing/assets/img/svg/shadowRoadmap.svg";
import shadowIcon from "src/modules/landing/assets/img/svg/shadowIcon.svg";
import shadowRoad from "src/modules/landing/assets/img/png/shadowRoad.png";
import chartLine from "src/modules/landing/assets/img/roadmap/chartLine.svg";
import link from "src/modules/landing/assets/img/roadmap/link.svg";
import spinner from "src/modules/landing/assets/img/roadmap/spinner.svg";
import trending from "src/modules/landing/assets/img/roadmap/trending.svg";
import users from "src/modules/landing/assets/img/roadmap/users.svg";
import first from "src/modules/landing/assets/img/roadmap/first.svg";
import {useState} from "react";

export const Roadmap = () => {

    const [typeData, setTypeData] = useState("q1");

    const data = {
        "q3": [
            {
                img: first,
                title: "First Pillar",
                description: "Building up SigmaVerse to the point of high engagement and virality. First vital point for the ecosystem."
            },
            {
                img: users,
                title: "Community Growth",
                description: "First marketing wave to boost SigmaVerse userbase. One of the first public appearances."
            },
            {
                img: trending,
                title: "Trading Platform Launch",
                description: "PepeX beta launch. Public testing, collecting feedback, building up product value and volume with our community. Referral contest."
            },
            {
                img: spinner,
                title: "NFT Pre-launch",
                description: "Landing page launch. Community Whitelist contest. Leaderboard referral competition."
            },
        ],
        "q1": [
            {
                img: pencil,
                title: "PvP Online Game Launch",
                description: "Early access for NFT holders + Mystery Boxes. Second part of NFT utility revealed."
            },
            {
                img: bolt,
                title: "SigmaVerse -> PvP",
                description: "Onboarding campaign for SigmaVerse users into the PvP mode."
            },
            {
                img: lightnings,
                title: "NFT Second Coming",
                description: "Second collection + Launchpad announcement."
            },
            {
                img: upload,
                title: "Launchpad Demo-launch",
                description: "Second NFT collection sale through our launchpad."
            },
            {
                img: earth,
                title: "Merchandise Launch + IRL Mass-Adoption Campaign",
                description: ""
            },
        ],
        "q4": [
            {
                img: pencil,
                title: "PvP Online Game Launch",
                description: "Early access for NFT holders + Mystery Boxes. Second part of NFT utility revealed."
            },
            {
                img: bolt,
                title: "SigmaVerse -> PvP",
                description: "Onboarding campaign for SigmaVerse users into the PvP mode."
            },
            {
                img: lightnings,
                title: "NFT Second Coming",
                description: "Second collection + Launchpad announcement."
            },
            {
                img: upload,
                title: "Launchpad Demo-launch",
                description: "Second NFT collection sale through our launchpad."
            },
            {
                img: earth,
                title: "Merchandise Launch + IRL Mass-Adoption Campaign",
                description: ""
            },
        ],
        "tba": [
            {
                img: link,
                title: "Major Brand Collaborations",
                description: "TBA"
            },
            {
                img: chartLine,
                title: "Scaling Meme-DeFi Economy Worldwide",
                description: "Forever"
            },
        ]
    }


    return (
        <div className={styles.content} id={"roadmap"}>
            <div className={styles.title}>
                <span>PepeX</span> Ecosystem Roadmap
            </div>
            <div className={styles.roadmap}>
				<div className={styles.road}>
					<div className={styles.leftColumn}>
						<div onClick={() => setTypeData("q3")}>
							<div>q3 2024</div>
							{typeData === "q3" && <img src={shadowRoad} alt="" className={styles.shadowRoad}/>}
						</div>
						<div onClick={() => setTypeData("q1")}>
							<div>q1 2025</div>
							{typeData === "q1" && <img src={shadowRoad} alt="" className={styles.shadowRoad}/>}
						</div>
					</div>
					<svg width="89" height="414" viewBox="0 0 89 414" fill="none" xmlns="http://www.w3.org/2000/svg">
						<circle fill={typeData==="q4"?"#FF0025":"none"} cx="76" cy="143.479" r="11" stroke="white" stroke-width="4"/>
						<circle fill={typeData==="q1"?"#FF0025":"none"} cx="13" cy="271.479" r="11" stroke="white" stroke-width="4"/>
						<circle fill={typeData==="q3"?"#FF0025":"none"} cx="13" cy="13.4785" r="11" stroke="white" stroke-width="4"/>
						<circle fill={typeData==="tba"?"#FF0025":"none"} cx="76" cy="400.479" r="11" stroke="white" stroke-width="4"/>
						<path fill-rule="evenodd" clip-rule="evenodd"
							  d="M15.5 26.2384C14.691 26.396 13.8551 26.4785 13 26.4785C12.1449 26.4785 11.309 26.396 10.5 26.2384V63.827C10.5 76.2535 20.5736 86.327 33 86.327H56C65.665 86.327 73.5 94.162 73.5 103.827V130.719C74.309 130.561 75.1449 130.479 76 130.479C76.8551 130.479 77.691 130.561 78.5 130.719V103.827C78.5 91.4006 68.4264 81.327 56 81.327H33C23.335 81.327 15.5 73.492 15.5 63.827V26.2384ZM73.5 188.177V156.238C74.309 156.396 75.1449 156.479 76 156.479C76.8551 156.479 77.691 156.396 78.5 156.238V188.177C78.5 200.604 68.4264 210.677 56 210.677H33C23.335 210.677 15.5 218.512 15.5 228.177V258.719C14.691 258.561 13.8551 258.479 13 258.479C12.1449 258.479 11.309 258.561 10.5 258.719V228.177C10.5 215.751 20.5736 205.677 33 205.677H56C65.665 205.677 73.5 197.842 73.5 188.177ZM10.5 284.238V319.323C10.5 331.749 20.5736 341.823 33 341.823H56C65.665 341.823 73.5 349.658 73.5 359.323V387.719C74.309 387.561 75.1449 387.478 76 387.478C76.8551 387.478 77.691 387.561 78.5 387.719V359.323C78.5 346.896 68.4264 336.823 56 336.823H33C23.335 336.823 15.5 328.988 15.5 319.323V284.238C14.691 284.396 13.8551 284.479 13 284.479C12.1449 284.479 11.309 284.396 10.5 284.238Z"
							  fill="white"/>
					</svg>
					<div className={styles.rightColumn}>
						<div onClick={() => setTypeData("q4")}>
							<div>q4 2024</div>
							{typeData === "q4" && <img src={shadowRoad} alt="" className={styles.shadowRoad}/>}
						</div>
						<div onClick={() => setTypeData("tba")}>
							<div>TBA</div>
							{typeData === "tba" && <img src={shadowRoad} alt="" className={styles.shadowRoad}/>}
						</div>
					</div>
				</div>
				<div className={styles.launch}>
					<img src={shadowRoadmap} alt=""/>
					<div className={styles.rows}>
						{data[typeData]?.map((el, i) =>
							<div className={styles.row}>
								<div className={styles.left}>
									<img src={shadowIcon} alt=""/>
									<img src={el.img} alt=""/>
								</div>
								<div className={styles.text}>
									<div>{el.title}</div>
									<div>{el.description}</div>
								</div>
							</div>)}
					</div>
				</div>
			</div>
		</div>
	);
};
