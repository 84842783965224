import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import { IntlProvider } from "react-intl";
import locales from "./assets/i18n";
import { StoreUser } from "./modules/profile/store/StoreUser";
import { createContext } from "react";

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);
const storeUser = new StoreUser();
export const Context = createContext({
	storeUser,
});
// For translations into languages
const messages = {
	...locales.en,
	...locales[storeUser.language],
};

root.render(
	<Context.Provider value={{ storeUser }}>
		<IntlProvider locale={storeUser.language} messages={messages}>
			<App />
		</IntlProvider>
	</Context.Provider>
);
