import { makeAutoObservable, runInAction } from "mobx";
import { TLanguage, objectInLocalStorage } from "../types/types";

export class StoreUser {
	language: TLanguage;

	constructor() {
		makeAutoObservable(this);

		const lang =
			typeof window !== "undefined" ? navigator.language.slice(0, 2) : "en";
		console.log("lang", lang);

		if (localStorage.getItem(objectInLocalStorage.language)) {
			this.language = localStorage.getItem(
				objectInLocalStorage.language
			) as TLanguage;
		} else {
			switch (lang) {
				case "en":
					this.language = "en";
					break;
				case "es":
					this.language = "es";
					break;
				case "fr":
					this.language = "fr";
					break;
				case "ja":
					this.language = "ja";
					break;
				case "ch":
					this.language = "ch";
					break;
				case "de":
					this.language = "de";
					break;
				case "ru":
					this.language = "ru";
					break;
				case "hu":
					this.language = "hu";
					break;
				default:
					this.language = "en";
			}
		}
	}

	changeLanguage = (language: TLanguage) => {
		runInAction(() => {
			this.language = language;
		});
		localStorage.setItem(objectInLocalStorage.language, language);
	};
}
