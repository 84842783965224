import React from "react";
import styles from "./SigmaScore.module.scss";
import ClaimButton from "../ClaimButton/ClaimButton";
import shadowSigmaScore from "src/modules/landing/assets/img/svg/shadowSigmaScore.svg";
import { HashLink } from "react-router-hash-link";

const SigmaScore = () => {
	return (
		<div className={styles.content}>
			<img src={shadowSigmaScore} alt={""} className={styles.shadow} />
			<div className={styles.title}>sigma score</div>
			<div className={styles.subTitle}>
				Higher score = higher value = <span>higher revenue</span>
			</div>
			<div className={styles.areaForStats}>
				<div className={styles.blocks}>
					<div className={styles.block1}>
						<div>common</div>
					</div>
					<div className={styles.block2}>
						<div>rare</div>
					</div>
					<div className={styles.block3}>
						<div>mythical</div>
					</div>
					<div className={styles.block4}>
						<div>legendary</div>
					</div>
				</div>
				<div className={styles.wProgressBar}>
					<div className={styles.progressBar}>
						<div className={styles.redRectangles}>
							<div className={styles.redRectangle}></div>
							<div></div>
							<div className={styles.redRectangle}></div>
							<div></div>
							<div className={styles.redRectangle}></div>
							<div></div>
							<div className={styles.redRectangle}></div>
							<div></div>
							<div className={styles.redRectangle}></div>
						</div>
					</div>
					<div className={styles.scale}>
						<div>0</div>
						<div></div>
						<div>150</div>
						<div></div>
						<div>300</div>
						<div></div>
						<div>400</div>
						<div></div>
						<div>500</div>
					</div>
				</div>
			</div>
			<HashLink smooth to="/#freeNft" className={styles.link}>
				<ClaimButton>claim free-mint</ClaimButton>
			</HashLink>
		</div>
	);
};

export default SigmaScore;
