import { useIntl } from "react-intl";
import styles from "./FreeNft.module.scss";
import redCoinBig from "src/modules/landing/assets/img/png/redCoinBig.png";
import redCoinBottom from "src/modules/landing/assets/img/png/redCoinBottom.png";
import redCoinTop from "src/modules/landing/assets/img/png/redCoinTop.png";
import arrow from "src/modules/landing/assets/img/svg/arrowFreeNft.svg";
import arrowRed from "src/modules/landing/assets/img/svg/arrowRedFreeNft.svg";
import redBox from "src/modules/landing/assets/img/png/redBox.png";
import cardFreeNft from "src/modules/landing/assets/img/png/cardFreeNft.png";
import cardFreeNft1 from "src/modules/landing/assets/img/png/cardFreeNft1.png";
import cardFreeNft2 from "src/modules/landing/assets/img/png/cardFreeNft2.png";
import cl from "classnames";
import ClaimButton from "../ClaimButton/ClaimButton";
import ScrollAnimation from "react-animate-on-scroll";
import shadow from "src/modules/landing/assets/img/svg/shadowFreeNft.svg";
import shadow1 from "src/modules/landing/assets/img/svg/shadowFreeNft1.svg";
import shadow2 from "src/modules/landing/assets/img/svg/shadowFreeNft2.svg";

export const FreeNft = () => {
	const intl = useIntl();

	return (
		<div className={styles.wrapper} id={"freeNft"}>
			<img src={shadow} alt="" className={styles.shadow} />
			<img src={shadow1} alt="" className={styles.shadow1} />
			<div className={styles.content}>
				<div className={styles.title}>
					{intl.formatMessage({ id: "GET A FREE NFT" })}
				</div>
				<div className={styles.block}>
					<img src={shadow2} alt="" className={styles.shadow2} />
					<div className={styles.left}>
						<div className={styles.leftTitle}>
							{intl.formatMessage({ id: "buy $4,000 of tokens" })}
						</div>
						<div className={styles.coins}>
							<img src={redCoinBig} alt="" className={styles.redCoinBig} />
							<div className={styles.smallCoins}>
								<img src={redCoinTop} alt="" className={styles.redCoinTop} />
								<img
									src={redCoinBottom}
									alt=""
									className={styles.redCoinBottom}
								/>
							</div>
						</div>
					</div>
					<img src={arrow} alt="" />
					<div className={styles.right}>
						<div className={styles.rightTitle}>
							{intl.formatMessage({ id: "get an nft for free ($2,000+)" })}
						</div>
						<div className={styles.rightContent}>
							<div className={styles.cards}>
								<img src={cardFreeNft} alt="" className={styles.cardFreeNft} />
								<img
									src={cardFreeNft1}
									alt=""
									className={styles.cardFreeNft1}
								/>
							</div>
							<img src={redBox} alt="" className={styles.redBox} />
							<img src={cardFreeNft2} alt="" className={styles.cardFreeNft2} />
						</div>
					</div>
				</div>
				<div className={styles.steps}>
					<div className={styles.step}>
						<div className={styles.top}>
							<div>
								{intl.formatMessage({ id: "buy NGP token for $4,000" })}
							</div>
						</div>
						<div className={styles.line}></div>
						<div className={styles.bottom}>
							<ul className={styles.list}>
								<li style={{ width: "200px" }}>
									{intl.formatMessage({
										id: "Use a Phantom or Solflare wallet",
									})}
								</li>
								<li>
									{intl.formatMessage({
										id: "Enter a password and save your seed phrase",
									})}
								</li>
							</ul>
							<div
								className={styles.btn}
								onClick={() => window.open("https://phantom.app/")}
							>
								<div>{intl.formatMessage({ id: "BUY TOKEN" })}</div>
								<img src={arrowRed} alt="" />
							</div>
						</div>
					</div>
					<img src={arrowRed} alt="" />
					<div className={cl(styles.step, styles.step1)}>
						<div className={styles.top}>
							<div>
								{intl.formatMessage({ id: "send a message to our support" })}
							</div>
						</div>
						<div className={styles.line}></div>
						<div className={styles.bottom}>
							<ul className={styles.list}>
								<li>{intl.formatMessage({ id: "Telegram: @pepexsupport" })}</li>
								<li>
									{intl.formatMessage({
										id: "Add your wallet address and buy amount",
									})}
								</li>
							</ul>
							<div
								className={styles.btn}
								style={{ width: "219px" }}
								onClick={() => window.open("https://t.me/pepexsupport")}
							>
								<div className={styles.btnText}>
									{intl.formatMessage({ id: "apply for free nft" })}
								</div>
								<img src={arrowRed} alt="" className={styles.arrowRed} />
							</div>
						</div>
					</div>
					<img src={arrowRed} alt="" />
					<div className={cl(styles.step, styles.step2)}>
						<div className={styles.top}>
							<div>{intl.formatMessage({ id: "claim a free-mint code" })}</div>
						</div>
						<div className={styles.line}></div>
						<div className={styles.bottom} style={{ height: "244px" }}>
							<ul className={styles.list}>
								<li>
									{intl.formatMessage({
										id: "Before the public launch begins, you will receive a free-mint code through connecting your wallet this website",
									})}
								</li>
								<li>
									{intl.formatMessage({
										id: "Follow our telegram to keep tract of announcements",
									})}
								</li>
							</ul>
							<div
								className={styles.btn}
								onClick={() => window.open("https://t.me/sigmaversenews")}
							>
								<div>{intl.formatMessage({ id: "follow us" })}</div>
								<img src={arrowRed} alt="" />
							</div>
						</div>
					</div>
				</div>
				<ScrollAnimation animateIn={styles.animateBtn} initiallyVisible={true}>
					<div className={styles.btns}>
						<ClaimButton
							className={styles.btnRed}
							onClick={() => {
								window.open("https://t.me/nextgenpepe/405");
							}}
						>
							buy $pepe
						</ClaimButton>
					</div>
				</ScrollAnimation>
			</div>
		</div>
	);
};
