import styles from "./FeesDistribution.module.scss";
import backgroundWhite from "src/modules/landing/assets/img/svg/whiteBackground1.svg";
import diagram from "src/modules/landing/assets/img/svg/diagram.svg";
import shadowDiagram from "src/modules/landing/assets/img/svg/shadowDiagram.svg";
import shadowDiagramMobile from "src/modules/landing/assets/img/svg/shadowDiagramMobile.svg";
import cl from "classnames";
import { useWindowSize } from "@uidotdev/usehooks";

export const FeesDistribution = () => {
	const windowSize = useWindowSize();

	return (
		<div className={styles.content} id={"tokenomics"}>
			<div className={styles.mainContent}>
				<img
					src={
						Number(windowSize.width) > 450 ? shadowDiagram : shadowDiagramMobile
					}
					alt=""
					className={styles.shadowDiagram}
				/>
				<img src={backgroundWhite} alt="" className={styles.backgroundWhite} />
				<div className={styles.right}></div>
				<div className={styles.left}></div>
				<div className={styles.title}>sigmaverse revenue generation</div>
				<div className={styles.subTitle}>$100K / month</div>
				<div className={styles.text}>first revenue target</div>
				<div className={styles.diagram}>
					<div className={styles.column}>
						<div className={cl(styles.row, styles.row1)}>
							<div>30%</div>
							<div>
								Questboard <br /> integrations
							</div>
							<div></div>
						</div>
						<div className={cl(styles.row, styles.row2)}>
							<div style={{ color: "#ce2c2c" }}>20%</div>
							<div>VIP Memberships</div>
							<div></div>
						</div>
					</div>
					<img src={diagram} alt="" className={styles.image} />
					<div className={styles.column}>
						<div className={cl(styles.row, styles.row3)}>
							<div style={{ color: "#ab5454" }}>30%</div>
							<div>Lootboxes</div>
							<div></div>
						</div>
						<div className={cl(styles.row, styles.row4)}>
							<div style={{ color: "#980000" }}>20%</div>
							<div>
								<span>Donation items</span>
								<br /> <span className={styles.textT}>And more!</span>
							</div>
							<div></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
