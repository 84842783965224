import en from "./en.json";
import es from "./es.json";
import fr from "./fr.json";
import ja from "./ja.json";
import ch from "./zh-CN.json";
import de from "./de.json";
import ru from "./ru.json";
import hu from "./hu.json";

export default {
	en,
	es,
	fr,
	ja,
	ch,
	ru,
	de,
	hu,
} as Record<string, any>;
